/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import AskName from '@/components/Game/AskName';
import GameScene from '@/components/Game/GameScene';
import AudioHelper from '@/helpers/AudioHelper';
import UrlUtils from '@/utils/url';
import GeneralUtils from '@/utils/general';
import music_disabled_icon from '@/assets/img/icons/music_disabled.png';
import music_enabled_icon from '@/assets/img/icons/music_enabled.png';
import { useModal } from '@/contexts/ModalContext';
import { useUserContext } from '@/contexts/UserContext';
import { getGame } from '@/effects/game';
import './styles.css';

const Game = () => {
    const gameSceneRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isActiveMusic, setIsActiveMusic] = useState(false);
    const [showAskName, setShowAskName] = useState(false);
    const [game, setGame] = useState(null);
    const [load, setLoad] = useState(0);
    const { user } = useUserContext();
    const { showModal } = useModal();

    const scale = (num, in_min, in_max, out_min, out_max) => {
        return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
    };

    const fetchGame = async () => {
        const fingerprint = await GeneralUtils.getFingerprint();
        const complement = UrlUtils.splitUrl(window.location.href).complement;

        await getGame(complement, {
            uuid: fingerprint
        }, (response) => {
            setGame(response.data);
        }, (error) => {
            showModal('error', error.message, error.status === 409 ? {
                text: 'Ir a inicio',
                callback: () => {
                    window.location.href = `${window.location.origin}/home`;
                },
                quitClose: true
            } : () => {
                window.location.href = `${window.location.origin}/home`;
            });
        });
    };

    useEffect(() => {
        if (game === null) return;

        if (user?.is_teacher) {
            window.location.href = `${window.location.origin}/home?command=openRanking&game=${game.id}`;
        } else if (user === null) {
            if (game.allowAnonymous) {
                setShowAskName(true);
            } else {
                window.location.href = `${window.location.origin}/home`;
            }
        }
    }, [user, game]);


    useEffect(() => {
        fetchGame();

        let start = null;

        const animate = (timestamp) => {
            if (!start) start = timestamp;
            const progress = timestamp - start;

            const newLoad = Math.min(100, load + (progress / 30));
            setLoad(newLoad);

            if (newLoad < 100) {
                window.requestAnimationFrame(animate);
            }
        };

        window.requestAnimationFrame(animate);

        return () => cancelAnimationFrame(animate);
    }, []);

    return (
        <>
            {(showAskName) && <AskName play={async (name) => {
                await gameSceneRef.current.handlePlay(name);

                setShowAskName(false);
            }} />}
            <div className="game_cover relative">
                <div
                    className="paper_texture_game flex justify-center items-center z-0 w-full h-screen absolute inset-0"
                    style={{ filter: `blur(${scale(load, 0, 100, 30, 0)}px)` }}
                >
                    <GameScene ref={gameSceneRef} game={game} playing={() => {
                        setIsPlaying(true);
                        setIsActiveMusic(true);
                    }} />
                </div>
                <div
                    className={`loading-text fixed inset-0 flex justify-center items-center text-white text-5xl ${load === 100 ? 'hidden' : null}`}
                    style={{ opacity: scale(load, 0, 90, 1, 0.1) }}
                >
                    {Math.floor(load)}%
                </div>
                <div className={`fixed top-5 left-5 ${load === 100 ? null : 'hidden'}`}>
                    <img
                        src={isActiveMusic ? music_enabled_icon : music_disabled_icon}
                        alt='Música'
                        className='h-10 w-10'
                        onClick={() => {
                            const newActiveStatus = !isActiveMusic;

                            setIsActiveMusic(newActiveStatus);

                            if (newActiveStatus) {
                                if (isPlaying) {
                                    AudioHelper.play('game', 0.50, true);
                                } else {
                                    AudioHelper.play('await', 0.2, true);
                                }
                            } else {
                                AudioHelper.stopAll()
                            }
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default Game;
