import React from 'react';
import logo from '@/assets/img/logo.png';
import './styles.css';

const Loading = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen loading">
      <img className='h-40' src={logo} alt="PreguntaMania" />
      <h1 className="mb-12 mt-12 font-semibold text-4xl text-slate-700">PreguntaMania</h1>
      <div className="flex gap-5 container justify-center">
        <div className="w-10 h-10 rounded-full dot bg-primary"></div>
        <div className="w-10 h-10 rounded-full dot bg-secondary"></div>
        <div className="w-10 h-10 rounded-full dot bg-tertiary"></div>
        <div className="w-10 h-10 rounded-full dot bg-accent"></div>
      </div>
    </div>
  );
};

export default Loading;
