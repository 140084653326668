import React, { useState } from 'react';
import Header from '@/components/General/Header';

const AskName = ({ play }) => {
    const [playerName, setPlayerName] = useState('');
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState(false);

    const validateForm = () => {
        const newErrors = {};
        if (!playerName.trim()) {
            newErrors.playerName = 'El nombre del jugador es obligatorio.';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSave = () => {
        setTouched(true);

        if (validateForm()) {
            play(playerName);
        }
    };

    const handleLoginRedirect = () => {
        window.location.replace('/login');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSave();
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
            <div className="bg-white p-6 w-full h-full md:overflow-hidden md:rounded-lg md:shadow-lg md:w-96 md:h-[265px] md:p-0">
                <Header className="md:hidden" label={
                    <span className='text-2xl font-semibold'>
                        Entrar como invitad@
                    </span>
                } />
                <div className='hidden bg-primary text-white text-2xl items-center justify-center h-14 text-medium md:flex'>
                    Entrar como invitado
                </div>
                <div className="flex flex-col p-0 md:p-6 md:h-[calc(100%-112px)]">
                    <h2 className="text-lg font-medium mb-4">Datos del jugador</h2>
                    <input
                        type="text"
                        placeholder="Nombre del jugador"
                        className={`border ${errors.playerName && touched ? 'border-red-500' : 'border-gray-300'} rounded w-full px-3 py-2 mb-4`}
                        value={playerName}
                        onChange={(e) => {
                            setPlayerName(e.target.value);
                            if (touched) {
                                setErrors({ ...errors, playerName: '' });
                            }
                        }}
                        onBlur={() => setTouched(true)}
                        onKeyPress={handleKeyPress}
                    />
                    {errors.playerName && touched && <p className="text-red-500 text-sm mb-2">{errors.playerName}</p>}
                </div>
                <div className="flex justify-center items-center border-t border-black-50 h-14 mx-6 md:justify-between">
                    <button
                        onClick={handleLoginRedirect}
                        className="bg-secondary text-white rounded px-4 py-2 mr-2 hidden md:flex h-auto"
                    >
                        Iniciar sesión
                    </button>
                    <button
                        onClick={handleSave}
                        className="bg-primary text-white rounded px-4 py-2 h-auto"
                    >
                        Jugar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AskName;
