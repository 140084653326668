/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import close_icon from '@/assets/img/icons/close.png';
import './styles.css';

const WriteOption = ({ close = null, option = null, success = null, error = null }) => {
    const [optionWrited, setOptionWrited] = useState('');
    const inputRef = useRef(null);
    
    const handleClose = () => {
        if (close !== null) {
            close();
        }
    };

    const handleSubmit = () => {
        if (optionWrited === option.value) {
            if (success !== null) {
                success(optionWrited);
            }
        } else {
            if (error !== null) {
                error(optionWrited);
            }
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                handleClose();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50">
            <div className="bg-white relative flex flex-col items-center justify-center p-7 md:overflow-hidden rounded-lg shadow-lg w-10/12 h-[250px] paper_texture_all">
                <img className="h-10 absolute top-1 right-1" src={close_icon} alt="Cerrar" onClick={handleClose} />
                <h2 className="text-lg font-medium mb-4 text-center">
                    Ahora tienes que escribir lo que elegiste
                </h2>
                <input
                    type="text"
                    placeholder={`${option.value}`}
                    className={`border rounded w-full px-3 py-2 mb-4`}
                    ref={inputRef}
                    onChange={(e) => {
                        setOptionWrited(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                />
                <button className="bg-primary text-white rounded px-4 py-2" onClick={handleSubmit}>
                    Continuar
                </button>
            </div>
        </div>
    );
};

export default WriteOption;
