const LocalStorage = {
    /**
     * Determines if the browser supports local storage.
     * 
     * @returns {boolean} True if local storage is supported, otherwise false.
     */
    isSupported() {
        return typeof Storage !== 'undefined';
    },

    /**
     * Checks if a specific key exists in local storage.
     *
     * @param {string} key - The key to check.
     * @returns {boolean} True if the key exists, otherwise false.
     */
    has(key) {
        return localStorage.hasOwnProperty(key);
    },

    /**
     * Retrieves a value from local storage and attempts to parse it if applicable.
     *
     * @param {string} key - The key associated with the value.
     * @returns {any} The retrieved value, parsed if necessary.
     */
    get(key) {
        let item = localStorage.getItem(key);

        if (typeof item !== 'string') return item;
        if (item === 'undefined') return undefined;
        if (item === 'null') return null;

        if (/^-?\d+(\.\d+)?$/.test(item)) return Number(item);  // Check for numbers and floats
        if (/^-?\d\.\d+e\+\d{2}$/.test(item)) return Number(item);  // Check for scientific notation

        if (item[0] === '{' || item[0] === '[') return JSON.parse(item);  // Check for serialized objects/arrays

        return item;
    },

    /**
     * Saves a value to local storage. Objects and arrays are automatically serialized.
     *
     * @param {string} key - The key under which the value is stored.
     * @param {any} value - The value to store.
     * @throws {TypeError} If the key is not a string.
     */
    set(key, value) {
        if (typeof key !== 'string') {
            throw new TypeError(`localStorage: Key must be a string. (reading '${key}')`);
        }

        if (typeof value === 'object' || Array.isArray(value)) {
            value = JSON.stringify(value);
        }

        localStorage.setItem(key, value);
    },

    /**
     * Removes a key-value pair from local storage.
     *
     * @param {string} key - The key to remove.
     */
    remove(key) {
        localStorage.removeItem(key);
    },
};

export default LocalStorage;
