import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useEventBus } from '@/contexts/EventBusContext';
import { useUserContext } from '@/contexts/UserContext';
import logo from '@/assets/img/logo.png';

const Header = ({ leading = null, className = '', label = null }) => {
    const { user } = useUserContext();
    const headerRef = useRef(null);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [canGoBack, setCanGoBack] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const historyRef = useRef([]);
    const { emit } = useEventBus();

    useEffect(() => {
        if (headerRef.current) {
            const height = headerRef.current.offsetHeight;
            setHeaderHeight(height);
        }

        if (historyRef.current.length === 0 || historyRef.current[historyRef.current.length - 1] !== location.pathname) {
            historyRef.current.push(location.pathname);
        }

        setCanGoBack(historyRef.current.length > 1);
    }, [location]);

    const isHome = location.pathname === "/home";
    const isIndex = location.pathname === "/";

    const handleBack = () => {
        if (historyRef.current.length > 1) {
            historyRef.current.pop();
            navigate(-1);
        }
    };

    const handleMenu = () => {
        emit('openSidenav');
    };

    return (
        <>
            <header
                ref={headerRef}
                className={`bg-white shadow p-2 flex items-center justify-between fixed top-0 right-0 left-0 md:p-4 ${className}`}
            >
                <div className="flex items-center">
                    {
                        leading === null ? (
                            isHome && user !== null ? (
                                <button onClick={handleMenu} className="text-primary mr-4 md:hidden">
                                    <span className="material-symbols-outlined">
                                        menu
                                    </span>
                                </button>
                            ) : (
                                <>
                                    {canGoBack && !isIndex ? (
                                        <button onClick={handleBack} className="text-primary mr-4">
                                            <span className="material-symbols-outlined">
                                                arrow_back
                                            </span>
                                        </button>
                                    ) : (
                                        user !== null ?
                                            <button onClick={handleMenu} className="text-primary mr-4 md:hidden">
                                                <span className="material-symbols-outlined">
                                                    menu
                                                </span>
                                            </button> : null
                                    )}
                                </>
                            )
                        ) : (
                            leading
                        )
                    }
                </div>
                <div className="flex items-center justify-center flex-grow">
                    {
                        label === null ? <Link to={user === null ? '/' : '/home'} className="flex items-center">
                            <img src={logo} alt="Logo de PreguntaMania" className="h-10 mr-2" />
                            <h1 className="text-3xl font-bold text-primary max-md:text-xl">PreguntaMania</h1>
                        </Link> : label
                    }
                </div>
            </header>
            <div className={`w-full ${leading !== null ? 'md:hidden' : null}`} style={{ height: `${headerHeight}px` }}></div>
        </>
    );
};

export default Header;
