import { executeSessionRequest } from ".";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getStudents = async (onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/admin_students`, "GET", null, onSuccess, onFail);

    return data;
};

export const deleteStudent = async (id, onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/admin_students/${id}`, "DELETE", null, onSuccess, onFail);

    return data;
};