import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { login } from '@/effects/session';
import { useModal } from '@/contexts/ModalContext';
import login_image from '@/assets/img/login.png';
import './styles.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [formAnimated, setFormAnimated] = useState(true); 
    const { showModal } = useModal();

    const validateFields = () => {
        const validationErrors = {};

        if (!email) {
            validationErrors.email = 'El correo electrónico es requerido.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            validationErrors.email = 'El correo electrónico no es válido.';
        }

        if (!password) {
            validationErrors.password = 'La contraseña es requerida.';
        }

        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateFields();

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            setFormAnimated(false);
            setTimeout(() => setFormAnimated(true), 10);
        }

        if (Object.keys(validationErrors).length === 0) {
            const payload = {
                email,
                password,
            };

            await login(payload, (data) => {
                if (data.status === 200) {
                    window.location.reload();
                }
            }, (error) => {
                showModal('error', error.message);
            });
        }
    };

    return (
        <main className="login flex flex-row items-center justify-center mt-6 paper_texture md:mt-0">
            <div className={`w-full md:w-96 animate__animated ${formAnimated ? 'animate__fadeIn' : 'animate__shakeX'}`}>
                <form
                    onSubmit={handleSubmit}
                    className="bg-white px-8 pb-8 md:rounded-lg md:overflow-hidden"
                >
                    <h2 className="text-2xl font-bold mb-4 pt-4 text-center">Iniciar Sesión</h2>
                    <div className='flex justify-center md:hidden'>
                        <img className='h-72 animate__animated animate__fadeInUp' src={login_image} alt="PreguntaMania" />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                            Correo Electrónico
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                            placeholder="Introduce tu correo electrónico"
                        />
                        {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Contraseña
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                            placeholder="Introduce tu contraseña"
                        />
                        {errors.password && <p className="text-red-500 text-xs italic">{errors.password}</p>}
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                            type="submit"
                            className="bg-primary text-white font-bold py-3 px-20 rounded-lg hover:bg-secondary animate__animated animate__pulse"
                        >
                            Iniciar Sesión
                        </button>
                    </div>
                    <div className="flex items-center mt-5">
                        <span className='mr-2'>¿No tienes una cuenta?</span>
                        <Link to="/register" className="text-secondary font-bold">
                            Registrate
                        </Link>
                    </div>
                </form>
            </div>
        </main>
    );
};

export default Login;
