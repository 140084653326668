import LocalStorage from "@/helpers/LocalStorage";

export const executeRequest = async (url, method = 'GET', body = null, onSuccess = null, onFail = null, onException = null, headers = {}, saveToken = false) => {
    if (headers === null) {
        headers = {};
    }

    headers['Content-Type'] = 'application/json';

    try {
        let payload = {
            method,
            headers
        };

        if (body !== null) {
            payload.body = JSON.stringify(body)
        }

        const response = await fetch(url, payload);
        const result = await response.json();

        if (response.ok) {
            if (typeof onSuccess === 'function') {
                onSuccess(result);
            }
        } else {
            if (typeof onFail === 'function') {
                onFail(result);
            }
        }

        if (saveToken) {
            LocalStorage.set('access-token', result.data.access_token);
        }

        return result;
    } catch (error) {
        if (typeof onException === 'function') {
            onFail(onException);
        }
    }
};

export const executeSessionRequest = async (url, method = 'GET', body = null, onSuccess = null, onFail = null, onException = null) => {
    const token = LocalStorage.get('access-token');
    const headers = {
        Authorization: `Bearer ${token}`
    };

    const result = await executeRequest(url, method, body, onSuccess, onFail, onException, headers, false);

    return result;
};