import FingerprintJS from '@fingerprintjs/fingerprintjs';

const GeneralUtils = {
    shuffleArray: (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    },
    minutesSince: (dateString) => {
        const pastDate = new Date(dateString);
        const currentDate = new Date();
        const differenceInMilliseconds = currentDate - pastDate;
        const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000);
        return differenceInMinutes;
    },
    getFingerprint: async () => {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        return result.visitorId;
    }
};

export default GeneralUtils;
