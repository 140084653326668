import { executeRequest, executeSessionRequest } from ".";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const login = async (payload, onSuccess, onFail) => {
    const data = await executeRequest(`${BASE_URL}/api/login`, "POST", payload, onSuccess, onFail, null, null, true);

    return data;
};

export const register = async (payload, onSuccess, onFail) => {
    const data = await executeRequest(`${BASE_URL}/api/register`, "POST", payload, onSuccess, onFail);

    return data;
};

export const logout = async (onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/logout`, "GET", null, onSuccess, onFail);

    return data;
};

export const me = async (onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/me`, "GET", null, onSuccess, onFail);

    return data;
};

export const event = async (onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/event`, "GET", null, onSuccess, onFail);

    return data;
};
