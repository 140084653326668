import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { register } from '@/effects/session';
import { useModal } from '@/contexts/ModalContext';
import './styles.css';

const Register = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('teachers');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [professorCode, setProfessorCode] = useState('');
    const [errors, setErrors] = useState({});
    const { showModal } = useModal();

    const validateFields = () => {
        const validationErrors = {};

        if (!name) {
            validationErrors.name = 'El nombre es requerido.';
        } else if (!/^[A-Za-zÀ-ÿ\s'-]+$/.test(name)) {
            validationErrors.name = 'El nombre solo debe contener letras y espacios.';
        }

        if (!email) {
            validationErrors.email = 'El correo electrónico es requerido.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            validationErrors.email = 'El correo electrónico no es válido.';
        }

        if (!password) {
            validationErrors.password = 'La contraseña es requerida.';
        } else if (password.length < 8 || !/\d/.test(password)) {
            validationErrors.password = 'La contraseña debe tener al menos 8 caracteres y un número.';
        }

        if (password !== confirmPassword) {
            validationErrors.confirmPassword = 'Las contraseñas no coinciden.';
        }

        if (!termsAccepted) {
            validationErrors.termsAccepted = 'Debes aceptar los términos y condiciones.';
        }

        if (activeTab === 'students' && (!/^\d{1,6}$/.test(professorCode) || professorCode === '')) {
            validationErrors.professorCode = 'El Código de Profesor es requerido y debe tener un máximo de 6 dígitos.';
        }

        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateFields();

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            const payload = {
                name,
                email,
                password,
                terms: termsAccepted,
                code_teacher: professorCode
            };

            await register(payload, (data) => {
                showModal('success', data.message, {
                    text: 'Iniciar sesión',
                    callback: () => {
                        navigate('/login');
                    }
                });
            }, (error) => {
                showModal('error', error.message);
            });
        }
    };

    return (
        <main className="register flex flex-row items-center justify-center mt-6 paper_texture md:mt-0 md:w-full">
            <div className="w-full md:w-96">
                <div className="w-full">
                    <div className="relative right-0">
                        <ul className="relative flex flex-wrap list-none bg-slate-100 md:rounded-t-lg md:overflow-hidden">
                            <li className={`z-30 flex-auto text-center ${activeTab === 'teachers' ? 'bg-secondary text-white font-semibold' : null}`} onClick={() => setActiveTab('teachers')}>
                                <button className="z-30 flex items-center justify-center w-full px-2 py-2 text-sm mb-0 transition-all ease-in-out border-0 cursor-pointer text-inherit">
                                    Profesores
                                </button>
                            </li>
                            <li className={`z-30 flex-auto text-center ${activeTab === 'students' ? 'bg-secondary text-white font-semibold' : null}`} onClick={() => setActiveTab('students')}>
                                <button className="z-30 flex items-center justify-center w-full px-2 py-2 mb-0 text-sm transition-all ease-in-out border-0 cursor-pointer text-inherit">
                                    Alumnos
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className="bg-white rounded-b-lg px-8 pb-8 mb-4">
                    <h2 className="text-2xl font-bold mb-4 pt-4 text-center">
                        {activeTab === 'teachers' ? 'Registro de Profesores' : 'Registro de Alumnos'}
                    </h2>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                            Nombre
                        </label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={() => setErrors((prevErrors) => ({ ...prevErrors, name: validateFields().name }))}
                            className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none ${errors.name ? 'border-red-500' : ''}`}
                            placeholder="Introduce tu nombre"
                        />
                        {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                            Correo Electrónico
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={() => setErrors((prevErrors) => ({ ...prevErrors, email: validateFields().email }))}
                            className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none ${errors.email ? 'border-red-500' : ''}`}
                            placeholder="Introduce tu correo electrónico"
                        />
                        {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
                    </div>
                    <div className="mb-4 relative">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Contraseña
                        </label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={() => setErrors((prevErrors) => ({ ...prevErrors, password: validateFields().password }))}
                            className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none ${errors.password ? 'border-red-500' : ''}`}
                            placeholder="Introduce tu contraseña"
                        />
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute right-2 top-10 text-gray-500"
                        >
                            <span className="material-symbols-outlined">
                                {showPassword ? 'visibility' : 'visibility_off'}
                            </span>
                        </button>
                        {errors.password && <p className="text-red-500 text-xs italic">{errors.password}</p>}
                    </div>
                    <div className="mb-6 relative">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirm-password">
                            Repetir Contraseña
                        </label>
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirm-password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            onBlur={() => setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: validateFields().confirmPassword }))}
                            className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none ${errors.confirmPassword ? 'border-red-500' : ''}`}
                            placeholder="Repite tu contraseña"
                        />
                        <button
                            type="button"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            className="absolute right-2 top-10 text-gray-500"
                        >
                            <span className="material-symbols-outlined">
                                {showConfirmPassword ? 'visibility' : 'visibility_off'}
                            </span>
                        </button>
                        {errors.confirmPassword && <p className="text-red-500 text-xs italic">{errors.confirmPassword}</p>}
                    </div>

                    {activeTab === 'students' && (
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="professorCode">
                                Código de Profesor
                            </label>
                            <input
                                type="text"
                                id="professorCode"
                                value={professorCode}
                                onChange={(e) => setProfessorCode(e.target.value)}
                                onBlur={() => setErrors((prevErrors) => ({ ...prevErrors, professorCode: validateFields().professorCode }))}
                                className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none ${errors.professorCode ? 'border-red-500' : ''}`}
                                placeholder="Introduce tu Código de Profesor"
                            />
                            {errors.professorCode && <p className="text-red-500 text-xs italic">{errors.professorCode}</p>}
                        </div>
                    )}
                    <div className="mb-4">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                checked={termsAccepted}
                                onChange={(e) => setTermsAccepted(e.target.checked)}
                                className="mr-2"
                            />
                            Acepto los
                            <Link to="/terms-and-conditions" className="pl-1 text-secondary font-bold">
                                términos y condiciones
                            </Link>
                        </label>
                        {errors.termsAccepted && <p className="text-red-500 text-xs italic">{errors.termsAccepted}</p>}
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                            type="submit"
                            className="bg-primary text-white font-bold py-3 px-20 rounded-lg hover:bg-secondary"
                        >
                            Registrarme
                        </button>
                    </div>
                    <div className="flex items-center mt-5">
                        <span className='mr-2'>¿Ya tienes una cuenta?</span>
                        <Link to="/login" className="text-secondary font-bold">
                            Inicia sesión
                        </Link>
                    </div>
                </form>
            </div>
        </main>
    );
};

export default Register;
