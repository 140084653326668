/* eslint-disable react-hooks/exhaustive-deps */
/* global M */
import React, { useEffect, useState } from 'react';
import { useEventBus } from '@/contexts/EventBusContext';
import LocalStorage from '@/helpers/LocalStorage';
import GravatarUtils from '@/utils/gravatar';
import ClipboardUtils from '@/utils/clipboard';
import { useModal } from '@/contexts/ModalContext';
import { useUserContext } from '@/contexts/UserContext';
import { logout } from '@/effects/session';
import './styles.css';

const MATERIALIZE_URL = process.env.REACT_APP_MATERIALIZE_URL;

const Sidenav = () => {
    const [selectedOption, setSelectedOption] = useState();
    const { user } = useUserContext();
    const [instance, setInstance] = useState(null);
    const { event, clear } = useEventBus();
    const { showModal } = useModal();
    const { emit } = useEventBus();

    const handleSelect = (option) => {
        setSelectedOption(option);

        close(() => {
            emit('selectTeacherMenu', option);
        });
    };

    const close = (callback) => {
        if (instance) {
            instance.close();

            setTimeout(() => {
                callback();
            }, 500);
        }
    };

    const exit = async () => {
        await logout((response) => {
            LocalStorage.remove("access-token");
            window.location.reload();
        }, () => {
            LocalStorage.remove("access-token");
            window.location.reload();
        });
    };

    const copyToClipboard = () => {
        close(() => {
            ClipboardUtils.copy(user?.code, () => {
                showModal('success', '¡Código copiado al portapapeles!', {
                    text: 'Cerrar',
                });
            }, () => {
                showModal('error', 'Vaya, ocurrió un error al copiar al portapapeles');
            });
        });
    };

    useEffect(() => {
        handleSelect('forms');

        const script = document.createElement('script');
        script.src = MATERIALIZE_URL;
        script.async = true;

        script.onload = () => {
            const elems = document.querySelector('.sidenav');
            const sidenavInstance = M.Sidenav.init(elems, {});

            setInstance(sidenavInstance);
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (event === 'openSidenav' && instance) {
            instance.open();
        }

        clear();
    }, [event, instance, clear]);

    return (
        <ul className="sidenav" id="mobile-demo">
            <li className="flex items-center p-4">
                <img
                    src={GravatarUtils.getGravatarUrl(user?.email)}
                    alt="User Avatar"
                    className="w-10 h-10 rounded-md mr-4"
                />
                <div className="flex flex-col">
                    <span className="font-bold text-lg">{user?.name}</span>
                    {
                        user?.code && <span className="text-sm text-gray-500 flex items-center">
                            {user?.code}
                            <span
                                className="material-symbols-outlined ml-2 cursor-pointer"
                                onClick={copyToClipboard}
                                title="Copiar código"
                            >
                                content_copy
                            </span>
                        </span>
                    }
                </div>
            </li>
            <li>
                <button
                    onClick={() => handleSelect('forms')}
                    className="flex items-center px-4 py-3 rounded-lg w-full text-left hover:bg-gray-100"
                >
                    <span className="material-symbols-outlined mr-4 text-lg">description</span>
                    <span className={`${selectedOption === 'forms' ? 'font-bold' : null}`}>Formularios</span>
                </button>
            </li>
            {
                user?.is_teacher && <>
                    <li>
                        <button
                            onClick={() => handleSelect('students')}
                            className="flex items-center px-4 py-3 rounded-lg w-full text-left hover:bg-gray-100"
                        >
                            <span className="material-symbols-outlined mr-4 text-lg">school</span>
                            <span>Estudiantes</span>
                        </button>
                    </li>
                </>
            }
            <li>
                <button
                    onClick={exit}
                    className="flex items-center px-4 py-3 rounded-lg w-full text-left hover:bg-gray-100"
                >
                    <span className="material-symbols-outlined mr-4 text-lg">logout</span>
                    <span>Salir</span>
                </button>
            </li>
        </ul>
    );
};

export default Sidenav;
