/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Header from '@/components/General/Header';
import CorrectAnswers from '@/components/Game/CorrectAnswers';
import { useModal } from '@/contexts/ModalContext';
import { ranking as callRanking, getAnswer } from '@/effects/answers';

const RankingTeacher = ({ onClose, form }) => {
    const [ranking, setRanking] = useState([]);
    const [showCorrectAnswers, setShowCorrectAnswers] = useState(false);
    const [questions, setQuestions] = useState(null);
    const [responses, setResponses] = useState(null);
    const { showModal } = useModal();
    const [isLoading, setIsLoading] = useState(false);
    let intervalId = null;

    const handleCloseModal = () => {
        if (onClose) {
            onClose();
        }
    };

    const makeCallRanking = async () => {
        if (isLoading) return;
        setIsLoading(true);
        await callRanking(form?.game_id, (responseRanking) => {
            setRanking(responseRanking.data);
            setIsLoading(false);
        }, (errorRanking) => {
            showModal('error', errorRanking.message);
            setIsLoading(false);
        });
    };

    const handleElementClick = async (element) => {
        if (element.note === null) {
            showModal('error', 'Aún no es posible consultar el resultado de las respuestas');
            return;
        }        

        let payload = null;
        if (element.is_anonymous) {
            payload = {
                anonymous_id: element.id
            };
        } else {
            payload = {
                student_id: element.id
            };
        }

        await getAnswer(payload, (response) => {
            setQuestions(response.data?.form_copy?.questions);
            setResponses(response.data?.questions_answer);

           setShowCorrectAnswers(true);
        }, (error) => {
            showModal('error', error.message);
        });
    };

    useEffect(() => {
        makeCallRanking();
        intervalId = setInterval(() => {
            if (!isLoading) {
                makeCallRanking();
            }
        }, 3000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <>
            {showCorrectAnswers && <CorrectAnswers questions={questions} responses={responses} close={() => {
                setShowCorrectAnswers(false);
                setQuestions(null);
                setResponses(null);
            }} />}
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
                <div className="bg-white p-6 w-full h-full md:overflow-hidden md:rounded-lg md:shadow-lg md:w-2/4 md:h-[525px] md:p-0">
                    <Header className="md:hidden" leading={<button className="text-primary mr-4" onClick={handleCloseModal}>
                        <span className="material-symbols-outlined">close</span>
                    </button>} label={<span className='text-2xl font-semibold'>
                        Ranking
                    </span>} />
                    <div className='hidden bg-primary text-white text-2xl items-center justify-center h-14 text-medium md:flex'>
                        <span className="css_loader h-6 w-6"></span>
                        <span className='ml-1.5'>Ranking</span>
                    </div>
                    <div className='flex flex-col p-0 md:p-6'>
                        {ranking.length === 0 ? (
                            <div className="flex items-center justify-center text-center text-gray-500 py-6 md:h-[365px]">
                                Aún nadie ha iniciado una partida.
                            </div>
                        ) : <div className='flex flex-col overflow-y-auto md:h-[365px]'>
                            {ranking.map((element, index) => (
                                <div
                                    key={index}
                                    className={`flex justify-between items-center py-3 px-5 rounded-lg mb-3 shadow-md cursor-pointer 
                                        ${index === 0 ? 'bg-yellow-300' : index === 1 ? 'bg-gray-300' : index === 2 ? 'bg-orange-300' : 'bg-white'}`}
                                    onClick={() => handleElementClick(element)}
                                >
                                    <span className="text-xl font-semibold text-gray-700">
                                        {index + 1}.
                                    </span>
                                    <span className="text-xl font-medium text-gray-900">
                                        {element.name}
                                    </span>
                                    {element.note === null ? <span className="css_loader h-8 w-8"></span> : <span className="text-xl font-bold text-green-500">
                                        {element.note}
                                    </span>}
                                </div>
                            ))}
                        </div>}
                        <div className="flex justify-center items-center border-t border-black-50 pt-4">
                            <button
                                onClick={handleCloseModal}
                                className="bg-accent text-white rounded px-4 py-2 mr-2"
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RankingTeacher;
