import React from 'react';

const Modal = ({ isOpen, onClose, type, message, buttonConfirm, buttonCancel }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="relative bg-white w-4/5 text-center rounded-lg overflow-hidden md:w-80">
                <div className={`flex justify-center items-center h-20 ${{
                    success: 'bg-success',
                    error: 'bg-error',
                    question: 'bg-blue-300',
                }[type] || ''}`}>
                    <span className="material-symbols-outlined text-white text-5xl">
                        {{
                            warning: 'warning',
                            success: 'check_circle',
                            error: 'error',
                            question: 'help_outline', 
                        }[type] || ''}
                    </span>
                </div>
                <div className="flex flex-col p-5">
                    <span className='text-lg font-medium'>
                        {{
                            warning: 'Advertencia',
                            success: 'Operación exitosa.',
                            error: 'Vaya, ocurrió un error',
                            question: 'Confirmación',
                        }[type] || ''}
                    </span>
                    <span className='mt-3'>{message}</span>
                    <button
                        className={`text-white font-bold py-3 px-20 rounded-lg mt-3 ${{
                            success: 'bg-success',
                            error: 'bg-error',
                            question: 'bg-blue-500',
                        }[type] || ''}`}
                        onClick={buttonConfirm ? async () => {
                            if (typeof buttonConfirm.callback === 'function') {
                                await buttonConfirm.callback();
                            }

                            if (!buttonConfirm?.quitClose) {
                                onClose();
                            }
                        } : onClose}
                    >
                        {buttonConfirm ? buttonConfirm.text : 'Cerrar'}
                    </button>
                    {buttonCancel && (
                        <button
                            className={`text-white font-bold py-3 px-20 rounded-lg mt-3 bg-secondary`}
                            onClick={buttonCancel.callback ? async () => {
                                if (typeof buttonCancel.callback === 'function') {
                                    await buttonCancel.callback();
                                }

                                onClose();
                            } : onClose}
                        >
                            {buttonCancel.text}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Modal;
