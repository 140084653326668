import awaitSound from '@/assets/sounds/await.mp3';
import gameSound from '@/assets/sounds/game.mp3';
import timeoutSound from '@/assets/sounds/timeout.mp3';
import gameoverSound from '@/assets/sounds/gameover.mp3';
import errorSound from '@/assets/sounds/error.mp3';
import correctSound from '@/assets/sounds/correct.mp3';

const AudioHelper = {
    sounds: {
        await: new Audio(awaitSound),
        game: new Audio(gameSound),
        timeout: new Audio(timeoutSound),
        gameover: new Audio(gameoverSound),
        error: new Audio(errorSound),
        correct: new Audio(correctSound),
    },

    /**
     * Plays a sound associated with the specified key.
     * 
     * @param {string} key - The key associated with the sound.
     * @param {number} volume - The volume level from 0 to 1.
     * @param {boolean} loop - Whether the sound should loop or not.
     */
    play(key, volume = 1, loop = false) {
        if (this.sounds[key]) {
            const audio = this.sounds[key];
            audio.currentTime = 0; // Reset the audio to the start

            // Set the volume (ensure it's within the range of 0 to 1)
            audio.volume = Math.max(0, Math.min(1, volume));

            // Set the loop property
            audio.loop = loop;

            // Play the audio
            audio.play().catch(error => {
                console.error(`Error playing sound "${key}":`, error);
            });
        } else {
            console.warn(`Sound with key "${key}" does not exist.`);
        }
    },

    /**
     * Pauses the specified sound.
     * 
     * @param {string} key - The key associated with the sound.
     */
    pause(key) {
        if (this.sounds[key]) {
            this.sounds[key].pause();
        } else {
            console.warn(`Sound with key "${key}" does not exist.`);
        }
    },

    /**
     * Stops the specified sound and resets it to the beginning.
     * 
     * @param {string} key - The key associated with the sound.
     */
    stop(key) {
        if (this.sounds[key]) {
            const audio = this.sounds[key];
            audio.pause();
            audio.currentTime = 0; // Reset to the beginning
        } else {
            console.warn(`Sound with key "${key}" does not exist.`);
        }
    },

    /**
     * Stops all currently playing sounds.
     */
    stopAll() {
        Object.keys(this.sounds).forEach((key) => {
            const audio = this.sounds[key];
            audio.pause();
            audio.currentTime = 0; // Reset to the beginning
        });
    }
};

export default AudioHelper;
