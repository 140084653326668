import { executeSessionRequest } from ".";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const saveForm = async (payload, onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/admin_forms`, "POST", payload, onSuccess, onFail);

    return data;
};

export const getForms = async (onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/admin_forms`, "GET", null, onSuccess, onFail);

    return data;
};

export const updateForm = async (payload, onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/admin_forms/${payload.id}`, "PUT", payload, onSuccess, onFail);

    return data;
};

export const deleteForm = async (id, onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/admin_forms/${id}`, "DELETE", null, onSuccess, onFail);

    return data;
};

export const shareForm = async (payload, onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/admin_forms/share/${payload.id}`, "POST", payload, onSuccess, onFail);

    return data;
};