const ClipboardUtils = {
    copy: async (text, success, errror) => {
        try {
            await navigator.clipboard.writeText(text);

            if (success) {
                success();
            }
        } catch (e) {
            if (errror) {
                errror(e);
            }
        }
    }
};

export default ClipboardUtils;
