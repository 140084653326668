import { executeSessionRequest } from ".";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getGame = async (hash, payload, onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/games/${hash}`, "POST", payload, onSuccess, onFail);

    return data;
};

export const play = async (id, onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/games/play/${id}`, "GET", null, onSuccess, onFail);

    return data;
};