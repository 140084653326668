import { executeSessionRequest } from ".";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const generateAnswer = async (hash, payload, onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/answers/generate/${hash}`, "POST", payload, onSuccess, onFail);

    return data;
};

export const completeAnswer = async (id, payload, onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/answers/complete/${id}`, "POST", payload, onSuccess, onFail);

    return data;
};

export const ranking = async (id, onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/answers/ranking/${id}`, "GET", null, onSuccess, onFail);

    return data;
};

export const getAnswer = async (payload, onSuccess, onFail) => {
    const data = await executeSessionRequest(`${BASE_URL}/api/answers/get`, "POST", payload, onSuccess, onFail);

    return data;
};
