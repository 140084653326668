import React from 'react';
import Sidebar from '@/components/General/Sidebar';
import Forms from '@/components/HomeStudent/Forms';

const HomeStudent = () => {
    return (
        <div className="home flex w-full">
            <Sidebar />
            <main className="flex justify-center paper_texture overflow-hidden overflow-y-auto w-full h-full">
                <Forms />
            </main>
        </div>
    );
}

export default HomeStudent;