import React, { useState, useEffect } from 'react';
import AddQuestion from '@/components/HomeTeacher/AddQuestion';
import Header from '@/components/General/Header';
import { useModal } from '@/contexts/ModalContext';
import { saveForm, updateForm } from '@/effects/admin_forms';
import { useEventBus } from '@/contexts/EventBusContext';

const AddNewForm = ({ onClose, form }) => {
    const [showAddQuestion, setShowAddQuestion] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [formName, setFormName] = useState('');
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({
        formName: false,
    });
    const [editedQuestion, setEditedQuestion] = useState(null);
    const { showModal } = useModal();
    const { emit } = useEventBus();

    useEffect(() => {
        if (form) {
            setFormName(form.name);
            setQuestions(form.questions);
        } else {
            setFormName('');
            setQuestions([]);
        }
    }, [form]);

    const handleCloseModal = () => {
        if (onClose) {
            onClose();
        }
    };

    const addQuestion = () => {
        setShowAddQuestion(true);
    };

    const handleSaveQuestion = (newQuestion) => {
        if (!isNaN(newQuestion?.index)) {
            const { index, ...restOfQuestion } = newQuestion;

            questions[newQuestion.index] = restOfQuestion;

            setQuestions(questions);
        } else {
            setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
        }

        setShowAddQuestion(false);
    };

    const handleDeleteQuestion = (qIndex) => {
        const updatedQuestions = questions.filter((_, index) => index !== qIndex);
        setQuestions(updatedQuestions);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formName.trim()) {
            newErrors.formName = 'El nombre del formulario es obligatorio.';
        }
        if (questions.length === 0) {
            newErrors.questions = 'Debes agregar al menos una pregunta.';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSaveForm = async () => {
        setTouched({ formName: true });

        if (validateForm()) {
            let action;
            let newForm = {
                name: formName,
                questions: questions,
            };

            if (form === null) {
                action = saveForm;
            } else {
                action = updateForm;
                newForm.id = form.id;
            }

            await action(newForm,
                (response) => {
                    emit('reloadAdminForms');

                    showModal('success', response.message, {
                        text: 'Cerrar'
                    });
                },
                (error) => {
                    showModal('error', error.message);
                }
            );

            if (!form) {
                setFormName('');
                setQuestions([]);
            }
            handleCloseModal();
        } else {
            if (errors.questions) {
                showModal('error', errors.questions);
            }
        }
    };

    return (
        <>
            {showAddQuestion && (
                <AddQuestion
                    onClose={() => {
                        setEditedQuestion(null);

                        setShowAddQuestion(false);
                    }}
                    onChange={(question) => handleSaveQuestion(question)}
                    question={editedQuestion}
                />
            )}
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
                <div className="bg-white p-6 w-full h-full md:overflow-hidden md:rounded-lg md:shadow-lg md:w-10/12 md:h-4/6 md:p-0">
                    <Header className="md:hidden" leading={
                        <button className="text-primary mr-4" onClick={handleCloseModal}>
                            <span className="material-symbols-outlined">close</span>
                        </button>
                    } label={
                        <span className='text-2xl font-semibold'>
                            {form ? 'Editar formulario' : 'Añadir formulario'}
                        </span>
                    } />
                    <div className='hidden bg-primary text-white text-2xl items-center justify-center h-14 text-medium md:flex'>
                        {form ? 'Editar formulario' : 'Añadir formulario'}
                    </div>
                    <div className="flex flex-col p-0 md:p-6  h-[calc(100%-112px)]">
                        <h2 className="text-lg font-medium mb-4">Datos del formulario</h2>
                        <input
                            type="text"
                            placeholder="Nombre del formulario"
                            className={`border ${errors.formName && touched.formName ? 'border-red-500' : 'border-gray-300'} rounded w-full px-3 py-2 mb-4`}
                            value={formName}
                            onChange={(e) => {
                                setFormName(e.target.value);
                                if (touched.formName) {
                                    setErrors({ ...errors, formName: '' });
                                }
                            }}
                            onBlur={() => {
                                setTouched({ formName: true });
                                validateForm();
                            }}
                        />
                        {errors.formName && touched.formName && <p className="text-red-500 text-sm mb-2">{errors.formName}</p>}
                        <h2 className="text-lg font-medium mb-4">Preguntas</h2>
                        <div className='flex flex-col justify-start items-center mb-4 w-full overflow-y-auto overflow-x-hidden'>
                            {questions.length === 0 && (
                                <span className='text-base font-medium text-slate-500'>
                                    No hay preguntas
                                </span>
                            )}
                            {questions.length > 0 && questions.map((question, qIndex) => (
                                <div key={qIndex} className='flex justify-between mb-4 p-4 border rounded-lg w-full'>
                                    <div className='flex flex-col flex-grow'>
                                        <span className='font-medium mb-2'>{question.label}</span>
                                        <div className='flex flex-col'>
                                            {question.options.map((option, oIndex) => (
                                                <div key={oIndex} className='flex items-center mb-2'>
                                                    <div
                                                        className={`w-4 h-4 rounded-full border-2 mr-2 cursor-pointer 
                                                        ${option.isCorrect ? 'bg-primary border-primary' : 'border-gray-300'}`}
                                                    />
                                                    <span>{option.value}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-between items-center ml-4' style={{ width: '40px' }}>
                                        <button
                                            className="flex justify-center items-center mb-2 text-gray-500 hover:text-gray-700"
                                            onClick={() => {
                                                question.index = qIndex;

                                                setEditedQuestion(question);

                                                setShowAddQuestion(true);
                                            }}
                                        >
                                            <span className="material-symbols-outlined">edit</span>
                                        </button>
                                        <button
                                            className="flex justify-center items-center text-gray-500 hover:text-red-500"
                                            onClick={() => handleDeleteQuestion(qIndex)}
                                        >
                                            <span className="material-symbols-outlined">delete</span>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex justify-between items-center border-t border-black-50 h-14 mx-6">
                        <button
                            onClick={addQuestion}
                            className="bg-gray-300 text-black rounded px-4 py-2 mr-2 h-auto"
                        >
                            Añadir pregunta
                        </button>
                        <div className="flex items-center">
                            <button
                                onClick={handleCloseModal}
                                className="bg-accent text-white rounded px-4 py-2 mr-2 hidden md:flex h-auto"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={handleSaveForm}
                                className="bg-primary text-white rounded px-4 py-2 h-auto"
                            >
                                {form ? 'Actualizar formulario' : 'Guardar formulario'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddNewForm;
