import React, { useState, useEffect } from 'react';
import { useUserContext } from '@/contexts/UserContext';
import Sidebar from '@/components/General/Sidebar';
import AdminForms from '@/components/HomeTeacher/AdminForms';
import AdminStudents from '@/components/HomeTeacher/AdminStudents';
import { useEventBus } from '@/contexts/EventBusContext';
import './styles.css';

const HomeTeacher = () => {
    const { user } = useUserContext();
    const [selectedOption, setSelectedOption] = useState('forms');
    const { event, clear, data } = useEventBus();

    useEffect(() => {
        if (event === 'selectTeacherMenu') {
            setSelectedOption(data);
        }
        
        clear();
    }, [event, clear, data]);

    const MainContent = ({ selectedOption }) => {
        switch (selectedOption) {   
            case 'forms':
                return <AdminForms />;
            case 'students':
                return <AdminStudents />;
            default:
                return <div>Seleccione una opción del menú</div>;
        }
    };

    return (
        <div className="home flex w-full">
            {
                user?.is_teacher && <Sidebar />
            }
            <main className="flex justify-center paper_texture overflow-hidden overflow-y-auto w-full h-full">
                <MainContent selectedOption={selectedOption} />
            </main>
        </div>
    );
};

export default HomeTeacher;
