const UrlUtils = {
    splitUrl(url) {
        const urlObject = new URL(url);
        const segments = urlObject.pathname.split('/').filter(Boolean);
        const complement = segments.length > 0 ? segments[segments.length - 1] : '';

        return {
            protocol: urlObject.protocol,
            domain: urlObject.hostname,
            path: `/${segments.slice(0, -1).join('/')}`,
            complement: complement,
            query: urlObject.search
        };
    }

};

export default UrlUtils;