/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import LocalStorage from '@/helpers/LocalStorage';
import GravatarUtils from '@/utils/gravatar';
import ClipboardUtils from '@/utils/clipboard';
import { useEventBus } from '@/contexts/EventBusContext';
import { useModal } from '@/contexts/ModalContext';
import { useUserContext } from '@/contexts/UserContext';
import { logout } from '@/effects/session';

const Sidebar = () => {
    const { user } = useUserContext();
    const [avatar, setAvatar] = useState();
    const [selectedOption, setSelectedOption] = useState();
    const { showModal } = useModal();
    const { emit } = useEventBus();

    const handleSelect = (option) => {
        setSelectedOption(option);

        emit('selectTeacherMenu', option);
    };

    const exit = async () => {
        await logout((response) => {
            LocalStorage.remove("access-token");
            window.location.reload();
        }, () => {
            LocalStorage.remove("access-token");
            window.location.reload();
        });
    };

    const copyToClipboard = () => {
        ClipboardUtils.copy(user?.code, () => {
            showModal('success', '¡Código copiado al portapapeles!', {
                text: 'Cerrar',
            });
        }, () => {
            showModal('error', 'Vaya, ocurrió un error al copiar al portapapeles');
        });
    };

    useEffect(() => {
        handleSelect('forms');

        setAvatar(GravatarUtils.getGravatarUrl(user?.email));
    }, []);

    return (
        <aside className="sidebar w-1/4 h-[calc(100vh-124px)] bg-white p-4 hidden md:flex md:flex-col">
            <header className="flex items-center mb-8 mt-8">
                <div className="flex items-center">
                    <span className="mr-4">
                        <img
                            src={avatar}
                            alt="User Avatar"
                            className="w-10 h-10 rounded-md"
                        />
                    </span>
                    <div className="flex flex-col">
                        <span className="font-bold text-lg">{user?.name}</span>
                        {
                            user?.code ? <span className="text-sm text-gray-500 flex items-center">
                                {user?.code}
                                <span
                                    className="material-symbols-outlined ml-2 cursor-pointer"
                                    onClick={copyToClipboard}
                                    title="Copiar código"
                                >
                                    content_copy
                                </span>
                            </span> : null
                        }
                    </div>
                </div>
            </header>

            <ul className="menu-links flex flex-col space-y-4">
                <li className="nav-link">
                    <button
                        onClick={() => handleSelect('forms')}
                        className={`w-full flex items-center px-4 py-3 rounded-lg transition-colors duration-300 ${selectedOption === 'forms' ? 'bg-gray-200 text-black font-bold' : 'hover:bg-gray-100 text-gray-600'}`}
                    >
                        <span className="material-symbols-outlined mr-4 text-lg">description</span>
                        <span>Formularios</span>
                    </button>
                </li>
                {
                    user?.is_teacher ? <li className="nav-link">
                        <button
                            onClick={() => handleSelect('students')}
                            className={`w-full flex items-center px-4 py-3 rounded-lg transition-colors duration-300 ${selectedOption === 'students' ? 'bg-gray-200 text-black font-bold' : 'hover:bg-gray-100 text-gray-600'}`}
                        >
                            <span className="material-symbols-outlined mr-4 text-lg">school</span>
                            <span>Estudiantes</span>
                        </button>
                    </li> : null
                }
                <li className="nav-link">
                    <button
                        onClick={exit}
                        className="w-full flex items-center px-4 py-3 rounded-lg hover:bg-gray-100 text-gray-600 transition-colors duration-300"
                    >
                        <span className="material-symbols-outlined mr-4 text-lg">logout</span>
                        <span>Salir</span>
                    </button>
                </li>
            </ul>
        </aside>
    );
};

export default Sidebar;
