import React from 'react';

const TermsAndConditionsPage = () => {
    return (
        <main className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h1 className="text-3xl font-bold mb-6 text-center">Términos y Condiciones</h1>

            <section className="mb-4">
                <h2 className="text-2xl font-semibold mb-2">1. Introducción</h2>
                <p className="text-gray-700">
                    Bienvenido a nuestra aplicación. Estos términos y condiciones rigen tu uso de nuestra
                    aplicación y servicios. Al acceder, descargar o utilizar la aplicación, aceptas los términos establecidos
                    a continuación. Si no aceptas estos términos, por favor no utilices la aplicación.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-2xl font-semibold mb-2">2. Uso de la Aplicación</h2>
                <p className="text-gray-700">
                    Esta aplicación es proporcionada para uso personal y no comercial. Te comprometes a no utilizar la
                    aplicación para fines ilícitos, fraudulentos o de manera que pueda dañar, deshabilitar o perjudicar el
                    rendimiento de la misma. Queda prohibido copiar, modificar, distribuir o transmitir cualquier parte
                    de la aplicación sin nuestro consentimiento por escrito.
                </p>
                <p className="text-gray-700">
                    El incumplimiento de estos términos puede resultar en la suspensión o eliminación de tu cuenta sin
                    previo aviso.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-2xl font-semibold mb-2">3. Registro y Seguridad de la Cuenta</h2>
                <p className="text-gray-700">
                    Para acceder a algunas funciones de la aplicación, puede ser necesario crear una cuenta. Eres responsable
                    de mantener la confidencialidad de tu contraseña y la seguridad de tu cuenta. Cualquier actividad
                    realizada a través de tu cuenta es tu responsabilidad.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-2xl font-semibold mb-2">4. Propiedad Intelectual</h2>
                <p className="text-gray-700">
                    Todo el contenido de esta aplicación, incluidos, entre otros, textos, gráficos, logotipos, íconos,
                    imágenes, y software, es de nuestra propiedad o de terceros que nos han otorgado licencia, y está
                    protegido por las leyes de propiedad intelectual. No puedes reproducir, distribuir, modificar ni utilizar
                    el contenido de la aplicación sin autorización expresa.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-2xl font-semibold mb-2">5. Tarifas y Pagos</h2>
                <p className="text-gray-700">
                    Algunos servicios de la aplicación pueden estar sujetos a tarifas. Te comprometes a pagar cualquier
                    tarifa aplicable según lo especificado en la aplicación. Nos reservamos el derecho de cambiar nuestras
                    tarifas en cualquier momento, y dichos cambios se te comunicarán a través de la aplicación o por otros
                    medios.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-2xl font-semibold mb-2">6. Limitación de Responsabilidad</h2>
                <p className="text-gray-700">
                    En la medida permitida por la ley, no seremos responsables de ninguna pérdida o daño, directo o indirecto,
                    que resulte del uso o incapacidad de uso de nuestra aplicación, incluidos, entre otros, pérdidas de datos,
                    interrupciones del servicio o fallos del sistema.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-2xl font-semibold mb-2">7. Actualizaciones y Cambios en los Términos</h2>
                <p className="text-gray-700">
                    Podemos actualizar estos términos y condiciones en cualquier momento sin previo aviso. Cualquier cambio será
                    efectivo inmediatamente después de su publicación. Te recomendamos que revises estos términos de manera
                    periódica para estar informado de cualquier cambio.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-2xl font-semibold mb-2">8. Política de Privacidad</h2>
                <p className="text-gray-700">
                    Tu privacidad es importante para nosotros. Nuestra política de privacidad explica cómo recopilamos,
                    utilizamos y compartimos tu información personal. Al usar esta aplicación, aceptas las prácticas
                    descritas en la política de privacidad.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-2xl font-semibold mb-2">9. Ley Aplicable</h2>
                <p className="text-gray-700">
                    Estos términos y condiciones se regirán e interpretarán de acuerdo con las leyes del país o estado
                    en el que estés utilizando el servicio. Cualquier disputa relacionada con estos términos será
                    resuelta en los tribunales de dicha jurisdicción.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-2xl font-semibold mb-2">10. Contacto</h2>
                <p className="text-gray-700">
                    Si tienes alguna pregunta sobre estos términos y condiciones o sobre la aplicación, puedes contactarnos
                    a través de nuestro soporte técnico en info@preguntamania.com.
                </p>
            </section>
        </main>
    );
};

export default TermsAndConditionsPage;
