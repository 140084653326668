import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Counter from '@/components/Game/Counter';
import WriteOption from '@/components/Game/WriteOption';
import CorrectAnswers from '@/components/Game/CorrectAnswers';
import AudioHelper from '@/helpers/AudioHelper';
import GeneralUtils from '@/utils/general';
import play from '@/assets/img/icons/play.png';
import { useModal } from '@/contexts/ModalContext';
import { generateAnswer, completeAnswer, ranking as callRanking } from '@/effects/answers';

const GameScene = forwardRef(({ game, playing = null }, ref) => {
    const [time, setTime] = useState(45);
    const [actualTime, setActualTime] = useState(null);
    const [responseTime, setResponseTime] = useState(null);
    const [showWriteOption, setShowWriteOption] = useState(false);
    const [showCorrectAnswers, setShowCorrectAnswers] = useState(false);
    const [showRanking, setShowRanking] = useState(false);
    const [showCounter, setShowCounter] = useState(true);
    const [isAwating, setIsAwating] = useState(true);
    const [answer, setAnswer] = useState(true);
    const [responses, setResponses] = useState([]);
    const [actualQuestion, setActualQuestion] = useState(null);
    const [optionSelected, setOptionSelected] = useState(null);
    const [ranking, setRanking] = useState(null);
    const { showModal } = useModal();

    const handlePlay = async (name = null) => {
        const fingerprint = await GeneralUtils.getFingerprint();

        await generateAnswer(game.hash, name === null ? null : {
            anonymous: {
                id: fingerprint,
                name: name
            }
        }, (response) => {
            AudioHelper.stopAll();
            AudioHelper.play('game', 0.30, true);

            let actualQuestions = response.data.form_copy.questions;
            GeneralUtils.shuffleArray(actualQuestions);

            actualQuestions = actualQuestions.map((question) => {
                let actualResponses = question.responses;
                GeneralUtils.shuffleArray(actualResponses);
                question.responses = actualResponses;
                return question;
            });

            response.data.form_copy.questions = actualQuestions;

            setIsAwating(false);
            setAnswer(response.data);
            setActualQuestion(0);

            if (playing !== null) {
                playing(true);
            }
        }, (error) => {
            showModal('error', error.message);
        });
    };

    useImperativeHandle(ref, () => ({
        handlePlay
    }));

    const handleSelectResponse = (index, response) => {
        let callback;

        setResponseTime(index);

        if (response.isCorrect) {
            callback = () => {
                setOptionSelected(response);
                setShowWriteOption(true);
            };
        } else {
            callback = () => {
                handleNext(response);
            };
        }

        setTimeout(() => {
            setResponseTime(null);

            callback();
        }, response.isCorrect ? 500 : 1000);
    };

    const handleNext = async (response) => {
        setShowWriteOption(false);

        if (response === null) {
            AudioHelper.play('error', 1);
        }

        responses.push({
            question_id: answer.form_copy.questions[actualQuestion].id,
            response: response === null ? null : response,
            time: time - actualTime
        });

        setResponses(responses);

        if (answer.form_copy.questions.length === (actualQuestion + 1)) {
            await completeAnswer(answer.id, {
                questions_answer: responses
            }, async (result) => {
                await callRanking(game.id, (responseRanking) => {
                    setRanking(responseRanking.data);
                    setShowRanking(true);
                }, (errorRanking) => {
                    showModal('error', errorRanking.message);
                });
            }, (error) => {
                showModal('error', error.message);
            });
        } else {
            setActualQuestion(actualQuestion + 1);
            setShowCounter(false);
            setTime(45);
            window.requestAnimationFrame(() => {
                setShowCounter(true);
            });
        }
    };

    const goHome = () => {
        window.location.href = `${window.location.origin}/home`;
    };

    return (
        <>
            {showWriteOption && <WriteOption option={optionSelected} close={() => setShowWriteOption(false)} success={(writed) => {
                AudioHelper.play('correct', 1);
                handleNext({ ...optionSelected, writed });
            }} error={(writed) => {
                AudioHelper.play('error', 1);
                handleNext({ ...optionSelected, writed });
            }} />}
            {showCorrectAnswers && <CorrectAnswers questions={answer?.form_copy?.questions} responses={responses} close={() => setShowCorrectAnswers(false)} />}
            <div className="flex items-center justify-center bg-white bg-opacity-70 p-8 rounded-lg w-11/12 h-4/5 shadow-lg">
                {isAwating && (
                    <div className="flex flex-col items-center justify-center">
                        <img className="h-40 w-auto animate__animated animate__pulse animate__infinite cursor-pointer" src={play} alt="Iniciar el juego" onClick={() => handlePlay()} />
                        <div className="mt-8 p-4 bg-gray-100 rounded-lg shadow-md text-center">
                            <h3 className="text-xl font-bold text-gray-800 mb-4">Reglas del juego</h3>
                            <ul className="list-decimal list-inside space-y-2 text-gray-700 text-base">
                                <li>No puedes regresar a preguntas anteriores.</li>
                                <li>Responde antes de que el tiempo se acabe, o se marcará como incorrecta.</li>
                                <li>Escribe la respuesta correctamente, de lo contrario será incorrecta.</li>
                                <li>Si sales del juego ya no podrás continuar.</li>
                            </ul>
                        </div>
                    </div>
                )}
                {!isAwating && !showRanking && <>
                    {showCounter && <Counter count={time} change={(timeChanged) => {
                        setActualTime(timeChanged);
                        if (timeChanged < 10) {
                            if (timeChanged === 0) {
                                AudioHelper.stop('gameover');
                                AudioHelper.play('gameover', 0.6);
                                handleNext('timeout');
                            } else {
                                AudioHelper.play('timeout', 0.5);
                            }
                        }
                    }} />}
                    <div className='flex flex-col'>
                        <span className="text-2xl font-semibold text-gray-800 mb-4 text-center">{answer.form_copy.questions[actualQuestion].label}</span>
                        <div className='grid grid-cols-2 gap-4 mt-20'>
                            {answer.form_copy.questions[actualQuestion].responses.map((response, index) => (
                                <button className={`text-white py-3 px-6 rounded-lg ${responseTime === index ? (response.isCorrect ? 'bg-primary' : 'bg-accent') : 'bg-secondary'}`} onClick={() => handleSelectResponse(index, response)} key={index}>
                                    {response.value}
                                </button>
                            ))}
                        </div>
                        <button className='bg-accent text-white py-3 px-6 rounded-lg mt-20' onClick={() => handleNext(null)}>
                            Saltar pregunta
                        </button>
                    </div>
                </>}
                {showRanking && (
                    <div className="w-full h-full">
                        <div className='flex justify-between mb-8'>
                            <span className="material-symbols-outlined text-3xl cursor-pointer" onClick={goHome}>
                                home
                            </span>
                            <h2 className="text-3xl font-bold text-center text-gray-800">Ranking</h2>
                            <span className="material-symbols-outlined text-3xl cursor-pointer" onClick={() => setShowCorrectAnswers(true)}>
                                star
                            </span>
                        </div>
                        {ranking.map((element, index) => (
                            <div key={index} className={`flex justify-between items-center py-3 px-5 rounded-lg mb-3 shadow-md 
                            ${index === 0 ? 'bg-yellow-300' : index === 1 ? 'bg-gray-300' : index === 2 ? 'bg-orange-300' : 'bg-white'}`}>
                                <span className="text-xl font-semibold text-gray-700">{index + 1}.</span>
                                <span className="text-xl font-medium text-gray-900">{element.name}</span>
                                <span className="text-xl font-bold text-green-500">{element.note}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
});

export default GameScene;
