import React, { createContext, useContext, useState } from 'react';

const EventBusContext = createContext();

export const EventBusProvider = ({ children }) => {
  const [event, setEvent] = useState(null);
  const [data, setData] = useState(null);

  const emit = (eventValue, dataValue) => {
    setEvent(eventValue);
    setData(dataValue);
  };

  const clear = () => {
    setEvent(null);
    setData(null);
  };

  return (
    <EventBusContext.Provider value={{ emit, event, data, clear }}>
      {children}
    </EventBusContext.Provider>
  );
};

export const useEventBus = () => useContext(EventBusContext);
