import MD5 from 'crypto-js/md5';

const GravatarUtils = {
    getGravatarUrl(email) {
        if (!email || typeof email !== 'string' || email.trim() === '') {
            return 'https://www.gravatar.com/avatar/?d=mp';
        }

        const cleanEmail = email.trim().toLowerCase();
        const md5Hash = MD5(cleanEmail).toString();

        return `https://www.gravatar.com/avatar/${md5Hash}`;
    }
};

export default GravatarUtils;
