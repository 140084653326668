import React, { useState, useEffect } from 'react';
import Header from '@/components/General/Header';
import './styles.css';

const AddQuestion = ({ onClose, onChange, question }) => {
    const [questionText, setQuestionText] = useState('');
    const [options, setOptions] = useState({
        A: '',
        B: '',
        C: '',
        D: ''
    });
    const [correctOption, setCorrectOption] = useState('');
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({
        questionText: false,
        A: false,
        B: false,
        C: false,
        D: false,
        correctOption: false,
    });

    useEffect(() => {
        if (question) {
            setQuestionText(question.label);
            const newOptions = {};

            question.options.forEach((option, index) => {
                const optionKey = String.fromCharCode(65 + index);
                newOptions[optionKey] = option.value;
                if (option.isCorrect) {
                    setCorrectOption(optionKey);
                }
            });

            setOptions(newOptions);
        } else {
            resetForm();
        }
    }, [question]);

    const resetForm = () => {
        setQuestionText('');
        setOptions({
            A: '',
            B: '',
            C: '',
            D: ''
        });
        setCorrectOption('');
        setErrors({});
        setTouched({
            questionText: false,
            A: false,
            B: false,
            C: false,
            D: false,
            correctOption: false,
        });
    };

    const handleCloseModal = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleOptionChange = (option, value) => {
        setOptions(prevOptions => ({
            ...prevOptions,
            [option]: value
        }));

        validateOption(option, value);
    };

    const validateQuestion = () => {
        const newErrors = {};
        if (!questionText.trim()) {
            newErrors.questionText = 'El texto de la pregunta es requerido.';
        } else if (questionText.length > 300) {
            newErrors.questionText = 'El texto de la pregunta no puede exceder los 300 caracteres.';
        }

        ['A', 'B', 'C', 'D'].forEach(option => {
            if (!options[option].trim()) {
                newErrors[option] = `La opción ${option} es requerida.`;
            } else if (options[option].length > 100) {
                newErrors[option] = `La opción ${option} no puede exceder los 100 caracteres.`;
            }
        });

        if (!correctOption) {
            newErrors.correctOption = 'Debes seleccionar una opción correcta.';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSaveQuestion = () => {
        setTouched({
            questionText: true,
            A: true,
            B: true,
            C: true,
            D: true,
            correctOption: true,
        });

        if (validateQuestion()) {
            let newQuestion = {
                label: questionText,
                options: Object.entries(options).map(([key, value]) => {
                    return {
                        value: value,
                        isCorrect: correctOption === key
                    };
                })
            };

            if (question) {
                newQuestion.id = question.id;
                newQuestion.index = question.index;
            }

            onChange(newQuestion);

            handleCloseModal();
        }
    };

    const validateOption = (option, value) => {
        const newErrors = { ...errors };

        if (!value.trim()) {
            newErrors[option] = `La opción ${option} es requerida.`;
        } else if (value.length > 100) {
            newErrors[option] = `La opción ${option} no puede exceder los 100 caracteres.`;
        } else {
            delete newErrors[option];
        }

        setErrors(newErrors);
    };

    const handleBlur = (field) => {
        setTouched(prevTouched => ({
            ...prevTouched,
            [field]: true
        }));

        validateQuestion();
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 w-full h-full md:overflow-hidden md:rounded-lg md:shadow-lg md:w-2/4 md:h-[525px] md:p-0">
                <Header className="md:hidden" leading={
                    <button className="text-primary mr-4" onClick={handleCloseModal}>
                        <span className="material-symbols-outlined">close</span>
                    </button>
                } label={
                    <span className='text-2xl font-semibold'>
                        {question ? 'Editar pregunta' : 'Añadir pregunta'}
                    </span>
                } />
                <div className='hidden bg-primary text-white text-2xl items-center justify-center h-14 text-medium md:flex'>
                    {question ? 'Editar pregunta' : 'Añadir pregunta'}
                </div>
                <div className='flex flex-col p-0 md:p-6'>
                    <h2 className="text-lg font-medium mb-4">
                        {question ? 'Editar pregunta' : 'Agregar pregunta'}
                    </h2>
                    <input
                        type="text"
                        placeholder="Texto de la pregunta"
                        className={`border ${errors.questionText && touched.questionText ? 'border-red-500' : 'border-gray-300'} rounded w-full px-3 py-2 mb-4`}
                        value={questionText}
                        onChange={(e) => {
                            setQuestionText(e.target.value);
                            setErrors({ ...errors, questionText: '' });
                        }}
                        onBlur={() => handleBlur('questionText')}
                    />
                    {errors.questionText && touched.questionText && <p className="text-red-500 text-sm mb-2">{errors.questionText}</p>}

                    <h2 className="text-lg font-medium mb-4">Agrega opciones de respuesta</h2>

                    <div className="mb-4">
                        {['A', 'B', 'C', 'D'].map(option => (
                            <div className='flex flex-col mb-2' key={option}>
                                <div className="flex items-center">
                                    <input
                                        type="radio"
                                        name="correctOption"
                                        value={option}
                                        checked={correctOption === option}
                                        onChange={() => setCorrectOption(option)}
                                        className={`mr-2 ${errors[option] && touched[option] ? 'border-red-500' : 'border-gray-300'} rounded-full`}
                                    />
                                    <input
                                        type="text"
                                        placeholder={`Opción ${option}`}
                                        className={`border ${errors[option] && touched[option] ? 'border-red-500' : 'border-gray-300'} rounded w-full px-3 py-2`}
                                        value={options[option]}
                                        onChange={(e) => handleOptionChange(option, e.target.value)}
                                        onBlur={() => handleBlur(option)}
                                    />
                                </div>
                                {errors[option] && touched[option] && <p className="text-red-500 text-sm mt-2">{errors[option]}</p>}
                            </div>
                        ))}
                    </div>
                    {errors.correctOption && touched.correctOption && <p className="text-red-500 text-sm mb-2">{errors.correctOption}</p>}

                    <div className="flex justify-center md:justify-end items-center border-t border-black-50 pt-4">
                        <button
                            onClick={handleCloseModal}
                            className="bg-accent text-white rounded px-4 py-2 mr-2"
                        >
                            Cancelar
                        </button>
                        <button
                            onClick={handleSaveQuestion}
                            className="bg-primary text-white rounded px-4 py-2"
                        >
                            {question ? 'Actualizar pregunta' : 'Guardar pregunta'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddQuestion;
