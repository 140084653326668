import React, { useState } from 'react';
import Header from '@/components/General/Header';
import ClipboardUtils from '@/utils/clipboard';
import { useModal } from '@/contexts/ModalContext';
import { useEventBus } from '@/contexts/EventBusContext';
import { shareForm as shareFormRequest } from '@/effects/admin_forms';

const ShareForm = ({ onClose, form }) => {
    const [allowAnonymous, setAllowAnonymous] = useState(false);
    const [allowMultipleAttempts, setAllowMultipleAttempts] = useState(false);
    const { showModal } = useModal();
    const { emit } = useEventBus();

    const handleCloseModal = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleCheckboxChangeAllowAnonymous = () => {
        setAllowAnonymous(!allowAnonymous);
    };

    const handleCheckboxChangeAllowMultipleAttempts = () => {
        setAllowMultipleAttempts(!allowMultipleAttempts);
    };

    const handleShareForm = async () => {
        const payload = {
            id: form.id,
            allowAnonymous: allowAnonymous,
            allowMultipleAttempts: allowMultipleAttempts
        };

        await shareFormRequest(payload, (response) => {
            emit('reloadAdminForms');

            handleCloseModal();

            showModal('success', response.message, {
                text: 'Copiar link',
                callback: () => {
                    setTimeout(() => {
                        ClipboardUtils.copy(`${window.location.origin}/game/${response.data.hash}`, () => {
                            showModal('success', '¡Código copiado al portapapeles!', {
                                text: 'Cerrar',
                            });
                        }, () => {
                            showModal('error', 'Vaya, ocurrió un error al copiar al portapapeles');
                        });
                    }, 500);
                }
            });
        }, (error) => {
            showModal('error', error.message);
        });
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
            <div className="bg-white p-6 w-full h-full md:overflow-hidden md:rounded-lg md:shadow-lg md:w-96 md:h-56 md:p-0">
                <Header className="md:hidden" leading={
                    <button className="text-primary mr-4" onClick={handleCloseModal}>
                        <span className="material-symbols-outlined">close</span>
                    </button>
                } />
                <div className='hidden bg-primary text-white text-2xl items-center justify-center h-14 text-medium md:flex'>
                    Compartir formulario
                </div>
                <div className="flex flex-col p-0 md:p-6 md:h-[calc(100%-112px)]">
                    <label className="flex items-center mb-2">
                        <input
                            type="checkbox"
                            checked={allowAnonymous}
                            onChange={handleCheckboxChangeAllowAnonymous}
                            className="mr-2"
                        />
                        Permitir acceso anónimo
                    </label>
                    <label className="flex items-center mb-2">
                        <input
                            type="checkbox"
                            checked={allowMultipleAttempts}
                            onChange={handleCheckboxChangeAllowMultipleAttempts}
                            className="mr-2"
                        />
                        Permitir múltiples intentos
                    </label>
                </div>
                <div className="flex justify-between items-center border-t border-black-50 h-14 mx-6">
                    <button
                        onClick={handleCloseModal}
                        className="bg-accent text-white rounded px-4 py-2 mr-2 hidden md:flex h-auto"
                    >
                        Cancelar
                    </button>
                    <button
                        className="bg-primary text-white rounded px-4 py-2 h-auto"
                        onClick={handleShareForm}
                    >
                        Compartir formulario
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ShareForm;
