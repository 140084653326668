/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useUserContext } from '@/contexts/UserContext';
import Sidenav from '@/components/General/Sidenav';
import Header from '@/components/General/Header';
import Footer from '@/components/General/Footer';
import Landing from '@/views/Landing';
import Login from '@/views/Login';
import Register from '@/views/Register';
import Game from '@/views/Game';
import HomeTeacher from '@/views/HomeTeacher';
import HomeStudent from '@/views/HomeStudent';
import TermsAndConditionsPage from '@/views/TermsAndConditionsPage';
import Loading from '@/components/General/Loading';
import LocalStorage from '@/helpers/LocalStorage';
import { me, event } from '@/effects/session';
import '@/App.css';

let isAuthenticated = false;

const PublicRoute = ({ element }) => {
    return !isAuthenticated ? element : <Navigate to="/home" />;
};

const PrivateRoute = ({ element }) => {
    return isAuthenticated ? element : <Navigate to="/login" />;
};

const App = () => {
    const { user } = useUserContext();
    const { login, logout } = useUserContext();
    const [showLoading, setShowLoading] = useState(true);
    const [isTimeSpent, setIsTimeSpent] = useState(false);

    const isGameRoute = window.location.pathname.startsWith("/game");

    const makeEventRequest = async () => {
        await event();
    };

    useEffect(() => {
        let intervalId;

        const fetchData = async () => {
            setTimeout(() => {
                setIsTimeSpent(true);
            }, 1500);

            await me(async (response) => {
                if (response.status === 200) {
                    isAuthenticated = true;
                    login(response.data);

                    if (!response.data?.is_teacher) {
                        await makeEventRequest();

                        intervalId = setInterval(async () => {
                            await makeEventRequest();
                        }, 30000);
                    }
                }

                setShowLoading(false);
            }, (data) => {
                if (data.status === 401) {
                    isAuthenticated = false;
                    LocalStorage.remove('access-token');
                    logout();
                }

                setShowLoading(false);
            });
        };

        fetchData();

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    return (
        <BrowserRouter>
            {(showLoading || !isTimeSpent) && !isGameRoute ? <Loading /> : (
                <>
                    {!isGameRoute && <Sidenav />}
                    {!isGameRoute && <Header />}
                    <Routes>
                        <Route path="/" element={<PublicRoute element={<Landing />} />} />
                        <Route path="/login" element={<PublicRoute element={<Login />} />} />
                        <Route path="/register" element={<PublicRoute element={<Register />} />} />
                        <Route path='/game/:hash' element={<Game />} />
                        <Route path="/home" element={<PrivateRoute element={user?.is_teacher ? <HomeTeacher /> : <HomeStudent />} />} />
                        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
                    </Routes>
                    {!isGameRoute && <Footer />}
                </>
            )}
        </BrowserRouter>
    );
};

export default App;
