/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import AddNewForm from '@/components/HomeTeacher/AddNewForm';
import ShareForm from '@/components/HomeTeacher/ShareForm';
import RankingTeacher from '@/components/HomeTeacher/RankingTeacher';
import ClipboardUtils from '@/utils/clipboard';
import empty from '@/assets/img/icons/empty.png';
import form_default from '@/assets/img/home/form_default.webp';
import { useEventBus } from '@/contexts/EventBusContext';
import { getForms, deleteForm } from '@/effects/admin_forms';
import { useModal } from '@/contexts/ModalContext';
import './styles.css';

const AdminForms = () => {
    const [forms, setForms] = useState([]);
    const [showAddNewForm, setShowAddNewForm] = useState(false);
    const [showShareForm, setShowShareForm] = useState(false);
    const [showRanking, setShowRanking] = useState(false);
    const [editedForm, setEditedForm] = useState(null);
    const [sharedForm, setSharedForm] = useState(null);
    const { emit, event, clear } = useEventBus();
    const { showModal } = useModal();

    const handleEdit = (form) => {
        setShowAddNewForm(true);
        setEditedForm(form);
    };

    const handleDelete = (id) => {
        showModal('question', '¿Estás seguro de que deseas eliminar este formulario?', {
            text: 'Confirmar',
            callback: async () => {
                await deleteForm(id, (response) => {
                    showModal('success', response.message, {
                        text: 'Cerrar',
                    });
                    emit('reloadAdminForms');
                }, (error) => {
                    showModal('error', error.message);
                });
            }
        }, {
            text: 'Cancelar'
        });
    };

    const handleShare = (form) => {
        setShowShareForm(true);
        setSharedForm(form);
    };

    const handleAddForm = () => {
        setShowAddNewForm(true);
    };

    const handleCloseAddNewForm = () => {
        setShowAddNewForm(false);
        setEditedForm(null);
    };

    const handleCloseShareForm = () => {
        setShowShareForm(false);
        setSharedForm(null);
    };

    const handleCloseRanking = () => {
        setShowRanking(false);
        setSharedForm(null);
    };

    const fetchForms = async () => {
        await getForms((response) => {
            setForms(response.data);

            const params = new URLSearchParams(window.location.search);

            const command = params.get('command');
            const game = Number(params.get('game'));

            const url = new URL(window.location);
            url.search = '';
            window.history.replaceState({}, '', url);

            if (command !== null && game !== null) {
                for (const form of response.data) {
                    if (form.game_id === game) {
                        openRankingTeacher(form);

                        break;
                    }
                }
            }
        }, (error) => {
            showModal('error', error.message);
        });
    };

    useEffect(() => {
        fetchForms();
    }, []);

    useEffect(() => {
        if (event === 'reloadAdminForms') {
            fetchForms();
        }
        clear();
    }, [event, clear]);

    const copyToClipboard = (text) => {
        ClipboardUtils.copy(`${window.location.origin}/game/${text}`, () => {
            showModal('success', '¡Código copiado al portapapeles!', {
                text: 'Cerrar',
            });
        }, () => {
            showModal('error', 'Vaya, ocurrió un error al copiar al portapapeles');
        });
    };

    const openRankingTeacher = (form) => {
        setSharedForm(form);

        setShowRanking(true);
    };

    return (
        <>
            {showAddNewForm && <AddNewForm onClose={handleCloseAddNewForm} form={editedForm} />}
            {showShareForm && <ShareForm onClose={handleCloseShareForm} form={sharedForm} />}
            {showRanking && <RankingTeacher onClose={handleCloseRanking} form={sharedForm} />}
            <div className="admin_forms grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full h-full p-4">
                {forms.length === 0 ? (
                    <div className="col-span-full flex flex-col items-center justify-center w-full h-full">
                        <img src={empty} alt="No Forms" className="w-12 h-12 mb-2" />
                        <p className="text-gray-500 text-lg">No hay formularios disponibles.</p>
                    </div>
                ) : (
                    forms.map((form, index) => (
                        <div key={index} className="bg-white border rounded-lg shadow-lg w-full h-auto flex flex-col mt-2 md:h-80 md:mt-0 relative">
                            <img
                                src={form_default}
                                alt={`Form ${index + 1}`}
                                className="w-full h-44 object-cover rounded-t-lg border-b border-gray-200 md:h-32"
                            />
                            {form.last_game_hash !== null && <div className='absolute top-1.5 right-1.5 flex'>
                                <button
                                    onClick={() => copyToClipboard(form.last_game_hash)}
                                    className="bg-gray-200 rounded-full h-8 w-8 flex justify-center items-center hover:bg-gray-300 transition duration-200"
                                >
                                    <span className="material-symbols-outlined text-lg">content_copy</span>
                                </button>
                                <button
                                    onClick={() => openRankingTeacher(form)}
                                    className="bg-gray-200 rounded-full h-8 w-8 ml-1.5 flex justify-center items-center hover:bg-gray-300 transition duration-200"
                                >
                                    <span className="material-symbols-outlined text-lg">star</span>
                                </button>
                            </div>}
                            <div className='flex flex-col justify-between flex-grow p-4'>
                                <div className="flex flex-col items-center justify-center text-center md:h-20">
                                    <h3 className="text-lg font-medium">{form.name}</h3>
                                    <span className='text-sm font-medium text-slate-500 mb-4'>
                                        {`${form.questions.length} ${form.questions.length === 1 ? 'pregunta' : 'preguntas'}`}
                                    </span>
                                </div>
                                <div className="flex justify-between pt-4 border-t border-gray-200">
                                    <button
                                        onClick={() => handleEdit(form)}
                                        className="flex-1 bg-primary text-white p-2 mx-1 rounded hover:bg-tertiary transition duration-200 text-sm"
                                    >
                                        <span className="material-symbols-outlined">edit</span>
                                    </button>
                                    <button
                                        onClick={() => handleDelete(form.id)}
                                        className="flex-1 bg-accent text-white p-2 mx-1 rounded hover:bg-secondary transition duration-200 text-sm"
                                    >
                                        <span className="material-symbols-outlined">delete</span>
                                    </button>
                                    <button
                                        onClick={() => handleShare(form)}
                                        className="flex-1 bg-secondary text-white p-2 mx-1 rounded hover:bg-green-600 transition duration-200 text-sm"
                                    >
                                        <span className="material-symbols-outlined">share</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
            <button
                onClick={handleAddForm}
                className="fixed bottom-4 right-4 bg-green-500 text-white rounded-full w-16 h-16 flex items-center justify-center shadow-lg hover:bg-green-600 transition duration-200"
            >
                <span className="material-symbols-outlined text-3xl">add</span>
            </button>
        </>
    );
};

export default AdminForms;
