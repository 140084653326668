import React from 'react';
import { Link } from 'react-router-dom';
import landing_image from '@/assets/img/landing.png';
import './styles.css';

const Landing = () => {
    return (
        <main className="home flex items-center w-full paper_texture">
            <div className='flex max-w-5xl mx-auto max-md:flex-col'>
                <div className="flex justify-center md:p-4">
                    <img
                        src={landing_image}
                        alt="Imagen de Landing"
                        className="h-[80vw] md:h-[450px]"
                    />
                </div>
                <div className="flex-1 p-4 flex flex-col justify-center items-center text-center">
                    <p className="text-3xl font-normal mb-5 mt-5">¡La forma divertida, efectiva y gratis de aprender!</p>
                    <div className="flex flex-col space-y-3">
                        <Link
                            to="/login" 
                            className="bg-primary text-white font-bold py-3 px-20 rounded-lg hover:bg-secondary"
                        >
                            Iniciar Sesión
                        </Link>
                        <Link
                            to="/register" 
                            className="bg-secondary text-white font-bold py-3 px-20 rounded-lg hover:bg-tertiary"
                        >
                            Registrarse
                        </Link>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Landing;
