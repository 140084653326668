/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useModal } from '@/contexts/ModalContext';
import { getForms } from '@/effects/forms';
import { play } from '@/effects/game';
import empty from '@/assets/img/icons/empty.png';
import form_default from '@/assets/img/home/form_default.webp';

const Forms = () => {
    const [forms, setForms] = useState([]);
    const { showModal } = useModal();

    const fetchForms = async () => {
        await getForms((response) => {
            setForms(response.data);
        }, (error) => {
            showModal('error', error.message);
        });
    };

    useEffect(() => {
        fetchForms();
    }, []);

    const handlePlay = async (id) => {
        await play(id, (response) => {
            window.location.href = `${window.location.origin}/game/${response.data.hash}`;
        }, (error) => {
            showModal('error', error.message);
        });
    };

    return <div className="admin_forms grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full h-full p-4">
        {forms.length === 0 ? (
            <div className="col-span-full flex flex-col items-center justify-center w-full h-full">
                <img src={empty} alt="No Forms" className="w-12 h-12 mb-2" />
                <p className="text-gray-500 text-lg">No hay formularios disponibles.</p>
            </div>
        ) : (
            forms.map((form, index) => (
                <div key={index} className="bg-white border rounded-lg shadow-lg w-full h-auto flex flex-col mt-2 md:h-80 md:mt-0">
                    <img
                        src={form_default}
                        alt={`Form ${index + 1}`}
                        className="w-full h-44 object-cover rounded-t-lg border-b border-gray-200 md:h-32"
                    />
                    <div className='flex flex-col justify-between flex-grow p-4'>
                        <div className="flex flex-col items-center justify-center text-center md:h-20">
                            <h3 className="text-lg font-medium">{form.name}</h3>
                            <span className='text-sm font-medium text-slate-500 mb-4'>
                                {`${form.questions} ${form.questions === 1 ? 'pregunta' : 'preguntas'}`}
                            </span>
                        </div>
                        <div className="flex justify-between pt-4 border-t border-gray-200">
                            <button
                                onClick={() => handlePlay(form.id)}
                                className="flex items-center justify-center bg-primary text-white p-2 mx-1 rounded w-full hover:bg-tertiary transition duration-200 text-sm"
                            >
                                <span className="material-symbols-outlined mr-2">play_arrow</span>
                                <span>Jugar</span>
                            </button>
                        </div>
                    </div>
                </div>
            ))
        )}
    </div>
};

export default Forms;
