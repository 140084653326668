/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useModal } from '@/contexts/ModalContext';
import { getStudents, deleteStudent } from '@/effects/students.js';
import empty from '@/assets/img/icons/empty.png';

const AdminStudents = () => {
    const [students, setStudents] = useState([]);
    const { showModal } = useModal();

    const fetchStudents = async () => {
        await getStudents((response) => {
            setStudents(response.data);
        }, (error) => {
            showModal('error', error.message);
        });
    };

    const handleDelete = (id) => {
        showModal('question', '¿Estás seguro de que deseas eliminar al estudiante?', {
            text: 'Confirmar',
            callback: async () => {
                await deleteStudent(id, (response) => {
                    showModal('success', response.message, {
                        text: 'Cerrar',
                    });

                    fetchStudents();
                }, (error) => {
                    showModal('error', error.message);
                });
            }
        }, {
            text: 'Cancelar'
        });
    };

    useEffect(() => {
        fetchStudents();

        const intervalId = setInterval(async () => {
            await fetchStudents();
        }, 30000);

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    return (
        <div className="admin_students p-4 w-full">
            {students.length === 0 ? (
                <div className="flex flex-col items-center justify-center w-full h-full">
                    <img src={empty} alt="No Students" className="w-12 h-12 mb-2" />
                    <p className="text-gray-500 text-lg">No hay estudiantes disponibles.</p>
                </div>
            ) : (
                <>
                    <div className="hidden md:block">
                        <table className="min-w-full bg-white border border-gray-200">
                            <thead>
                                <tr className="bg-gray-200 text-gray-600 uppercase text-sm">
                                    <th className="py-3 px-6 text-left">ID</th>
                                    <th className="py-3 px-6 text-left">Nombre</th>
                                    <th className="py-3 px-6 text-left">Correo</th>
                                    <th className="py-3 px-6 text-left">En línea</th>
                                    <th className="py-3 px-6 text-left">Acciones</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-700">
                                {students.map((student, index) => (
                                    <tr key={student.id} className="border-b hover:bg-gray-100">
                                        <td className="py-3 px-6">{index + 1}</td>
                                        <td className="py-3 px-6">{student.name}</td>
                                        <td className="py-3 px-6">{student.email}</td>
                                        <td className="py-3 px-6">
                                            {student.online ? (
                                                <div className="bg-primary h-4 w-4 rounded-full"></div>
                                            ) : (
                                                <div className="border border-gray-400 h-4 w-4 rounded-full"></div>
                                            )}
                                        </td>
                                        <td className="py-3 px-6">
                                            <button
                                                onClick={() => handleDelete(student.id)}
                                                className="flex-1 bg-accent text-white p-2 mx-1 rounded hover:bg-secondary transition duration-200 text-sm"
                                            >
                                                <span className="material-symbols-outlined">delete</span>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-1 gap-4 md:hidden">
                        {students.map((student, index) => (
                            <div key={student.id} className="bg-white border rounded-lg shadow-lg p-4 flex flex-col">
                                <h3 className="text-xl font-bold text-gray-800 mb-2 text-center">{student.name}</h3>
                                <p className="text-lg font-medium text-gray-600 mb-4 text-center">{student.email}</p>
                                <div className="flex flex-col">
                                    <div className="flex justify-between items-center mb-2">
                                        <span className="font-semibold text-gray-800">ID:</span>
                                        <span className="text-gray-500">{index + 1}</span>
                                    </div>
                                    <div className="flex justify-between items-center mb-2">
                                        <span className="font-semibold text-gray-800">En línea:</span>
                                        {student.online ? (
                                            <div className="bg-primary h-4 w-4 rounded-full"></div>
                                        ) : (
                                            <div className="border border-gray-400 h-4 w-4 rounded-full"></div>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <button
                                        onClick={() => handleDelete(student.id)}
                                        className="w-full bg-accent text-white p-2 rounded hover:bg-secondary transition duration-200 text-sm"
                                    >
                                        <span className="material-symbols-outlined">delete</span>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default AdminStudents;
