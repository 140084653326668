/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import './styles.css';

const Counter = ({ count = 45, change = null }) => {
    const numberSepared = {
        tens: Math.floor(count / 10),
        units: count % 10
    };

    useEffect(() => {
        const play = (selector) => {
            document.body.classList.remove("play");

            let aa = document.querySelector(`${selector} li.active`);

            if (!aa) {
                aa = document.querySelector(`${selector} li`);
                aa.classList.add("before");
                aa.classList.remove("active");
                aa.nextElementSibling?.classList.add("active");
            } else if (!aa.nextElementSibling) {
                document.querySelectorAll(`${selector} li`).forEach((li) => li.classList.remove("before"));
                aa.classList.add("before");
                aa.classList.remove("active");
                aa = document.querySelector(`${selector} li`);
                aa.classList.add("active");
            } else {
                document.querySelectorAll(`${selector} li`).forEach((li) => li.classList.remove("before"));
                aa.classList.add("before");
                aa.classList.remove("active");
                aa.nextElementSibling.classList.add("active");
            }

            document.body.classList.add("play");
        };

        const minuteInstanceList = document.querySelectorAll('ul.minutePlay li');
        minuteInstanceList.forEach((li) => {
            if (Number(li.querySelector('.minuteItem').innerHTML) === numberSepared.tens) {
                li.classList.add('active');
            }
        });

        const secondInstanceList = document.querySelectorAll('ul.secondPlay li');
        secondInstanceList.forEach((li) => {
            if (Number(li.querySelector('.secondItem').innerHTML) === numberSepared.units) {
                li.classList.add('active');

                play("ul.secondPlay");
            }
        });

        let vigilantUnits = 10 - numberSepared.units;
        let vigilantTens = numberSepared.tens;
        const secondsInterval = setInterval(() => {
            play("ul.secondPlay");

            vigilantUnits--;

            if (vigilantUnits < 0) {
                vigilantUnits = 9;

                play("ul.minutePlay");

                vigilantTens--;
            }

            const total = Number(`${vigilantTens}${vigilantUnits}`);

            if (total === 0) {
                clearInterval(secondsInterval);
            }

            if (change !== null) {
                change(total);
            }
        }, 1000);

        return () => {
            clearInterval(secondsInterval);
        };
    }, []);

    return (
        <div className="fixed top-5 right-5 z-50">
            <ul className="flip minutePlay">
                {[9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((num) => (
                    <li key={num}>
                        <div className="up relative w-full h-1/2 overflow-hidden">
                            <div className="shadow absolute w-full h-full z-10"></div>
                            <div className="minuteItem inn absolute w-full h-full text-center text-gray-300 bg-gray-800 rounded-lg shadow-md text-7xl font-bold">
                                {num}
                            </div>
                        </div>
                        <div className="down relative w-full h-1/2 overflow-hidden">
                            <div className="shadow absolute w-full h-full z-10"></div>
                            <div className="inn absolute w-full h-full text-center text-gray-300 bg-gray-800 rounded-lg shadow-md text-7xl font-bold">
                                {num}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            <ul className="flip secondPlay">
                {[9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((num) => (
                    <li key={num}>
                        <div className="up relative w-full h-1/2 overflow-hidden">
                            <div className="shadow absolute w-full h-full z-10"></div>
                            <div className="secondItem inn absolute w-full h-full text-center text-gray-300 bg-gray-800 rounded-lg shadow-md text-7xl font-bold">
                                {num}
                            </div>
                        </div>
                        <div className="down relative w-full h-1/2 overflow-hidden">
                            <div className="shadow absolute w-full h-full z-10"></div>
                            <div className="inn absolute w-full h-full text-center text-gray-300 bg-gray-800 rounded-lg shadow-md text-7xl font-bold">
                                {num}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Counter;
