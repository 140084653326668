import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-white text-center p-4">
            <p className="text-sm">&copy; 2024 PreguntaMania. Todos los derechos reservados.</p>
        </footer>
    );
};

export default Footer;
