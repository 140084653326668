import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '@/App';
import { UserProvider } from '@/contexts/UserContext'
import { ModalProvider } from '@/contexts/ModalContext';
import { EventBusProvider } from '@/contexts/EventBusContext';
import '@/index.css';
import 'animate.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
    <EventBusProvider>
      <ModalProvider>
        <App />
      </ModalProvider>
    </EventBusProvider>
  </UserProvider>
);
