/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import close_icon from '@/assets/img/icons/close.png';

const CorrectAnswers = ({ questions, responses, close = null }) => {
    const handleClose = () => {
        if (close !== null) {
            close();
        }
    };

    const getFilterResponsed = (question) => {
        const r = responses.find(response => response.question_id === question.id);
        return r;
    };

    const isChecked = (question, response) => {
        const r = getFilterResponsed(question);
        if (r?.response === null) return null;
        return r && r.response?.value === response?.value;
    };

    const getTime = (question) => getFilterResponsed(question)?.time;
    const getTimeLabel = (time) => (time === 1 ? 'segundo' : 'segundos');

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                handleClose();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const renderResponseMessage = (response, question) => {
        if (typeof response === 'object' && response !== null) {
            return <span className="text-sm ml-6">Tiempo de respuesta: {getTime(question)} {getTimeLabel(getTime(question))}</span>;
        }
        if (response === null) {
            return <span className="text-sm ml-6">La respuesta fue omitida</span>;
        }
        if (response === 'timeout') {
            return <span className="text-sm ml-6">Tiempo de respuesta agotado</span>;
        }
        return null;
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50">
            <div className="bg-white relative flex flex-col items-start p-7 rounded-lg shadow-lg w-full max-w-4xl h-full overflow-y-auto paper_texture_all">
                <img
                    className="h-10 absolute top-2 right-2 cursor-pointer"
                    src={close_icon}
                    alt="Cerrar"
                    onClick={handleClose}
                />
                <h2 className="text-2xl font-bold mb-6 w-full text-center">Respuestas Correctas</h2>
                <div className="bg-white w-full my-4 p-4 rounded-lg shadow-md md:flex">
                    <div className="mb-3">
                        <span className="font-bold text-gray-800">Notas:</span>
                    </div>
                    <div className="ml-1.5 text-gray-600">
                        <span className="flex items-center flex-wrap">
                            <span className="whitespace-normal">
                                Las respuestas que contienen un
                            </span>
                            <input
                                type="radio"
                                className="h-4 w-4 text-accent ml-2 mr-2 border-gray-300 focus:ring-accent"
                                defaultChecked={true}
                                readOnly
                                disabled
                            />
                            <span className="whitespace-normal">
                                son las seleccionadas por el usuario.
                            </span>
                        </span>
                        <span className="block mt-2">
                            Se indica el tiempo de respuesta; menos de 15 segundos otorga el máximo.
                        </span>
                        <span className="block mt-2">
                            Se indica si la respuesta fue omitada.
                        </span>
                        <span className="block mt-2">
                            Se indica si se agotó el tiempo para responder.
                        </span>
                        <span className="block mt-2">
                            Se muestra la mejor respuesta para el estudiante.
                        </span>
                    </div>
                </div>

                {questions
                    .sort((a, b) => a.id - b.id)
                    .map((question, i) => {
                        const response = getFilterResponsed(question).response;

                        return (
                            <div key={i} className="mb-6 w-full">
                                <div className="flex items-center mb-2">
                                    <span className="text-lg font-semibold mr-2 text-gray-700">{i + 1}.</span>
                                    <span className="text-lg font-semibold text-gray-900">{question.label}</span>
                                    {typeof response === 'object' && response !== null ? <span className='ml-2'>✅</span> : <span className='ml-2'>❌</span>}
                                </div>
                                <div className="flex flex-col ml-6">
                                    {question.responses.map((response, j) => (
                                        <div key={j} className="flex items-center mb-2">
                                            <input
                                                type="radio"
                                                name={`question-${i}`}
                                                className="mr-3 h-5 w-5 text-accent border-slate-200 focus:ring-accent"
                                                defaultChecked={isChecked(question, response)}
                                                readOnly
                                                disabled
                                            />
                                            <div className='flex'>
                                                <span className={`text-gray-800 ${response.isCorrect ? 'font-bold text-primary underline' : ''}`}>
                                                    {response.value}
                                                </span>
                                                {response.isCorrect && <span className='text-gray-800 ml-1'>(Respuesta correcta)</span>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {renderResponseMessage(response, question)}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default CorrectAnswers;
