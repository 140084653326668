import React, { createContext, useContext, useState } from 'react';
import Modal from '@/components/General/Modal';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('');
    const [buttonConfirm, setButtonConfirm] = useState(null);
    const [buttonCancel, setButtonCancel] = useState(null);

    const showModal = (type, message, buttonConfirm, buttonCancel) => {
        setType(type);
        setMessage(message);
        setButtonConfirm(buttonConfirm);
        setButtonCancel(buttonCancel);

        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <ModalContext.Provider value={{ showModal, closeModal }}>
            {children}
            <Modal isOpen={isOpen} onClose={closeModal} type={type} message={message} buttonConfirm={buttonConfirm} buttonCancel={buttonCancel} />
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    return useContext(ModalContext);
};
